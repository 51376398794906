<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-4">Sulfonylation of an amine</h2>
      <p class="pl-8 mb-5">
        <v-img max-width="450" src="/img/assignments/sulfonylation.png" />
      </p>
      <p class="mb-3">
        Methane sulfonyl chloride,
        <number-value :value="volR" unit="\text{mL}" />
        <stemble-latex content="$\text{or 1 eq}$" />
        was slowly added to a stirred cold solution containing
        <number-value :value="eqTEA" unit="\text{eq}" />
        of triethylamine and
        <number-value :value="eqPyrrolidine" unit="\text{eq}" />
        of pyrrolidine in dichloromethane
        <stemble-latex content="$\text{(0.2 M with respect to the limiting reagent).}$" />
        Evolution of a white smog was observed, which eventually vanished as the addition of methane
        sulfonyl chloride was completed. The reaction was left stirring overnight at room
        temperature.
      </p>

      <p class="mb-2">
        The reaction was then quenched with of saturated ammonium chloride, adding a little bit of
        water to the reaction mixture to dissolve any solids that may have formed. The aqueous layer
        was then extracted with
        <stemble-latex content="$\text{2-50 mL}$" />
        portions of dichloromethane and the organic layer was washed sequentially with
        <stemble-latex content="$\text{30 mL of 1M}$" />
        hydrochloric acid and
        <stemble-latex content="$\text{30 mL}$" />
        of a saturated sodium chloride solution. The organic layer was then dried over
        <stemble-latex content="$\text{1 g}$" />
        of magnesium sulfate, filtered, and solvent removed under vacuum to afford
        1-(methylsulfonyl)-pyrrolidine with a yield of
        <number-value :value="pctYield" unit="\%." />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import NumberValue from '../NumberValue';

export default {
  name: 'PercentYieldLab3',
  components: {
    CalculationInput,
    StembleLatex,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  computed: {
    volR() {
      return this.taskState.getValueBySymbol('volR').content;
    },
    eqTEA() {
      return this.taskState.getValueBySymbol('eqTEA').content;
    },
    eqPyrrolidine() {
      return this.taskState.getValueBySymbol('eqPyrrolidine').content;
    },
    pctYield() {
      return this.taskState.getValueBySymbol('pctYield').content;
    },
  },
};
</script>
